import '../App.css';

function Courses(){
    return(
        <div className="courses-page">
            <div className="courses-content">  
                <h2>Preparation Strategy for freshers</h2>
                <p>With the emergence of AI, IT companies are using more and more AI based solutions in their products.
                Hence it is very important for Freshers to gather decent amount of knowledge to get a chance to work for the companies.
                Let's decode the modern preparation strategy and identify the challenges there</p>
                
                <h3>Preparation for different career tracks</h3>
                <ul>
                    <li>Full Stack Developer</li>
                    <li>Data Scientist or Data Analyst</li>
                    <li>Machine Learning Engineer</li>
                </ul>
                
                <h3>Important Programming Languages</h3>
                <p>To prepare well for the Technical Interviews, currently it is not enough to know only one programming language.
                Hence following is the preferred list of programming languages which I personally feel is necessary to learn</p>
                <ol>
                    <li>Javascript</li>
                    <li>Python/Ruby</li>
                    <li>Java</li>
                    <li>Go</li>
                    <li>C++/Rust</li>
                </ol>
                
                <h2>Non Technical Preparation</h2>
                <p>Besides technical preparation it is also important to continue with non-technical stuff. Following sections come
                    under the non-technical things for which any candidate needs to be prepared about.</p>
                <ul>
                    <li>Communication Skills</li>
                    <li>Creating Personal Portfolio</li>
                        <li>Linkedin Profile</li>
                        <li>Github Profile</li>
                        <li>Personal Website</li>
                    <li>Technical writing skills</li>
                    <li>Cover letter writing</li>
                    <li>Preparing Curriculum Vitae(CV)</li>
                    <li>Apply for suitable Jobs/Internships through various channels</li>
                </ul>

                <h2>Roadmap</h2>
                <p>coming soon........</p>

            </div>
        </div>
    );
}

export default Courses;