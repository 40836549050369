import '../App.css';

function Footer(){
    return(
        <>
            <div className="App-footer">
                <span> Sagar Das 2024 © All Rights Reserved </span>
            </div>
        </>
    );
}


export default Footer;